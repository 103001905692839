const surveyQuestionType = {
    OPEN_ANSWER: 0,
    SINGLE_ANSWER: 1,
    MULTIPLE_ANSWERS: 2,
    BOOLEAN: 3,
    RATING: 4,
    MOOD: 5,
    NUMERIC: 6
}

export default surveyQuestionType;