const surveyReccurence = {
    Once: 0,
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
    TwiceDaily : 4,
    ThreeTimesDaily : 5,
    OnceEvery15Days : 6,
    OnceEvery21Days : 7,
    OnceEvery28Days : 8
}

export default surveyReccurence;